import AddIcon from '@mui/icons-material/Add'
import { Box, Fab, Paper, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import ReusableTableSkeleton from '~/components/Skeleton/ReusableTableSkeleton'
import ReusableTable from '~/components/Table/ReusableTable'
import Section from '~/components/UI/Section'
import translationLink from '~/helpers/utils/translationLink'
import { useUserListPostsQuery } from '~/redux/features/api/management-api-slice'

const Page = () => {
  const { data, isLoading, isSuccess } = useUserListPostsQuery({})

  // maybe remove the return of this information from the db if not used
  const columns_to_hide = ['id', 'admin', 'thumbnail', 'is_favorite', 'created_at', 'description']

  return (
    <Section
      size="big"
      padding={[
        { target: 'top', value: 'normal' },
        { target: 'bottom', value: 'none' },
      ]}
    >
      <Paper
        elevation={0}
        sx={{
          padding: 3,
          borderRadius: 2,
          backgroundColor: 'background.paper',
          justifyContent: 'space-between',
          display: 'flex',
        }}
      >
        <Stack width="stretch">
          {isLoading && <ReusableTableSkeleton />}

          {isSuccess && (
            <Stack direction="column" spacing={2} width="stretch" mb={6}>
              <Box>
                <Typography component="span" variant="h6" color="text.primary" mb={3}>
                  Services
                </Typography>{' '}
                <CreateFirstPostLink />
              </Box>

              {Boolean(data?.results?.length) && (
                <ReusableTable
                  data={data?.results ?? []}
                  columns_to_hide={columns_to_hide}
                  actionPath="posts"
                />
              )}
            </Stack>
          )}
        </Stack>
      </Paper>
    </Section>
  )
}

export default Page

const CreateFirstPostLink = () => (
  <Link to={translationLink('/management/posts/new')}>
    <Fab color="primary" size="small" aria-label="add product" variant="extended">
      <AddIcon />
    </Fab>
  </Link>
)
