import { updateCalendarData, updateCalendarLoading } from '~/redux/features/calendar/reducer'
import { apiSlice } from './api-slice'

export type GetCalendarProps = {
  month?: number
  postId?: number
  prefetch?: boolean
  updateState?: boolean
  year?: number
}

type UpdateAvailabilityProps = {
  postId: number
  duration_minutes: number
  admin: {
    id: number
    name: string
    account_type: string
    unavailabilities: Array<{
      start_datetime: Date
      end_datetime: Date
    }>
    bookings: Record<
      string,
      Array<{
        start_time: Date
        end_time: Date
      }>
    >
  }
  recurring_availability: Array<{
    id: number
    start_time: string
    end_time: string
    days_of_week: Array<'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday'>
  }>
}

export const calendarApiSlice = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['Calendar'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCalendar: builder.query<UpdateAvailabilityProps, GetCalendarProps>({
        query: ({ updateState, prefetch, ...params }) => {
          const { postId, year, month } = params

          // Early return if any required parameters are missing
          if (typeof postId !== 'number' || typeof year !== 'number' || typeof month !== 'number') {
            console.log('Missing required parameters', { postId, year, month })
            return ''
          }

          return {
            url: `post/${postId}/availability/${year}/${month}`,
          }
        },
        // onQueryStarted: async (
        //   { updateState = true, ...params },
        //   { dispatch, queryFulfilled, getState },
        // ) => {
        //   // Early return if any required parameters are missing
        //   if (!params?.postId) {
        //     console.log('Missing required parameters', { ...params })
        //     return void 0
        //   }
        //   // Access the cached data using `getState`
        //   const { data } = calendarApiSlice.endpoints.getCalendar.select(params)(getState())

        //   if (data) {
        //     console.log('Cached data:', data)
        //     // If cached data exists, dispatch updateCalendarData immediately
        //     dispatch(updateCalendarData({ ...data }))
        //   } else {
        //     // If cached data doesn't exist, fetch data and dispatch updateCalendarData
        //     try {
        //       // dispatch(updateCalendarLoading(true))
        //       const { data } = await queryFulfilled
        //       console.log('\n=== Calendar Api Slice: new Data ===')
        //       console.log(data)
        //       console.log('===\n')
        //       // Dispatch an action to update the calendar slice with the fetched data
        //       updateState && dispatch(updateCalendarData({ ...data }))
        //     } catch (error) {
        //       console.error('Failed to fetch data:', error)
        //     } finally {
        //       dispatch(updateCalendarLoading(false))
        //     }
        //   }
        // },
      }),
    }),
  })

export const {
  useGetCalendarQuery,
  useLazyGetCalendarQuery,
  usePrefetch: usePrefetchCalendar,
} = calendarApiSlice
