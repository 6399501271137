import { Dayjs } from 'dayjs'
import { NewClient, TopClient } from '~/helpers/types/client'
import ListResponse, { PaginationListInput } from '~/helpers/types/common'
import { Favorite } from '~/helpers/types/favorite'
import { GeneralMetricProps, GeneralMetricsByDate } from '~/helpers/types/general-metric'
import { Post } from '~/helpers/types/post'
import { Store } from '~/helpers/types/store'
import { PostContributionMetricsByDate } from '~/helpers/types/top-post'
import { urls } from '~/helpers/utils/urls'
import { apiSlice } from './api-slice'
import { postsApiSlice } from './posts-api-slice'

export type PostFavoriteFormDataValues = {
  post_id: number
  is_favorite: boolean
}

export const managementApiSlice = apiSlice
  .enhanceEndpoints({
    addTagTypes: [
      'ManagementStores',
      'ManagementPosts',
      'ManagementPostsMetric',
      'ManagementFavorites',
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      listStores: builder.query<ListResponse<Store>, PaginationListInput>({
        query: (args) => {
          const { page, limit } = args
          return `/management/store${urls.argsToParams({ page, limit })}`
        },
        providesTags: (result) =>
          result
            ? [
                ...result.results.map(({ id }) => ({
                  type: 'ManagementStores' as const,
                  id,
                })),
                { type: 'ManagementStores', id: 'LIST' },
              ]
            : [{ type: 'ManagementStores', id: 'LIST' }],
      }),
      addStore: builder.mutation({
        query: (body) => {
          return {
            url: '/management/store/new',
            method: 'POST',
            body,
          }
        },
        invalidatesTags: [{ type: 'ManagementStores', id: 'LIST' }],
      }),
      getStore: builder.query<Store, { id: number }>({
        query: (args) => {
          const { id } = args
          return `/management/store/${id}`
        },
        providesTags: (result) => [
          {
            type: 'ManagementStores' as const,
            id: result!.id,
          },
        ],
      }),
      updateStore: builder.mutation({
        query: (formData) => {
          const id = formData.get('id')
          return {
            url: `/management/store/${id}/update`,
            method: 'PATCH',
            body: formData,
          }
        },
        invalidatesTags: (result) => [
          {
            type: 'ManagementStores' as const,
            id: result!.id,
          },
        ],
      }),
      listStorePosts: builder.query<ListResponse<Post>, PaginationListInput>({
        query: (args) => {
          const { page, limit, store } = args
          return `management/store/${store}/posts${urls.argsToParams({
            page,
            limit,
          })}`
        },
        providesTags: (result) =>
          result
            ? [
                ...result.results.map(({ id }) => ({
                  type: 'ManagementPosts' as const,
                  id,
                })),
                { type: 'ManagementPosts', id: 'LIST' },
              ]
            : [{ type: 'ManagementPosts', id: 'LIST' }],
      }),
      createPost: builder.mutation({
        query: (data) => {
          console.log({ data })
          return {
            url: 'management/post/create',
            method: 'POST',
            body: sanitizePost(data),
          }
        },
        invalidatesTags: [{ type: 'ManagementPosts', id: 'LIST' }],
      }),
      deletePost: builder.mutation<void, { id: number }>({
        query: ({ ...data }) => {
          // check if id exists
          if (!data.id) throw new Error('Post ID is required')

          return {
            url: `/management/post/${data.id}/delete`,
            method: 'DELETE',
            body: data,
          }
        },
        invalidatesTags: (result, error, { id }) => [
          { type: 'ManagementPosts', id: 'LIST' },
          { type: 'ManagementPosts', id },
        ],
      }),
      updatePost: builder.mutation({
        query: (data) => ({
          url: `/management/post/${data.id}/update`,
          method: 'PATCH',
          body: sanitizePost(data),
        }),
        invalidatesTags: (result) => [
          { type: 'ManagementPosts', id: 'LIST' },
          { type: 'ManagementPosts', id: result.id },
        ],
      }),
      userListPosts: builder.query<ListResponse<Post>, PaginationListInput>({
        query: (args) => {
          const { page, limit } = args
          return {
            url: `management/posts`,
            params: {
              page,
              limit,
            },
          }
        },
        providesTags: (result) =>
          result
            ? [
                ...result.results.map(({ id }) => ({
                  type: 'ManagementPosts' as const,
                  id,
                })),
                { type: 'ManagementPosts', id: 'LIST' },
              ]
            : [{ type: 'ManagementPosts', id: 'LIST' }],
      }),
      getManagementPost: builder.query<Post, { id: number }>({
        query: (args) => {
          const { id } = args
          return `/management/post/${id}`
        },
        providesTags: (result, error, { id }) => [{ type: 'ManagementPosts', id }],
      }),
      getManagementMetric: builder.query<GeneralMetricsByDate, GeneralMetricProps>({
        query: (args) => {
          const { period } = args
          return `/management/metrics/general${urls.argsToParams({
            period,
          })}`
        },
        providesTags: (result) => [
          { type: 'ManagementPostsMetric', id: 'ManagementGeneralMetric' },
        ],
      }),
      getManagementNewClient: builder.query<NewClient[], {}>({
        query: (args) => {
          return '/management/metrics/clients/new-clients'
        },
      }),
      getManagementTopClient: builder.query<TopClient[], {}>({
        query: (args) => {
          return '/management/metrics/clients/top-clients'
        },
        providesTags: (result) => [{ type: 'ManagementPostsMetric', id: 'TopClient' }],
      }),
      getManagemenPostContribution: builder.query<PostContributionMetricsByDate, {}>({
        query: (args) => {
          return '/management/metrics/posts/top-posts'
        },
        providesTags: (result) => [{ type: 'ManagementPostsMetric', id: 'PostContribution' }],
      }),
      toggleFavoritePost: builder.mutation<
        PostFavoriteFormDataValues,
        { formData: FormData; post_id: number }
      >({
        query: (body) => {
          return {
            url: 'management/post/favorite/toggle',
            method: 'POST',
            body,
          }
        },
        invalidatesTags: (result) => [{ type: 'ManagementFavorites', id: 'LIST' }],
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled
            dispatch(postsApiSlice.util.invalidateTags([{ type: 'Post', id: arg.post_id }]))
          } catch (error) {
            console.error('Failed to update user:', error)
          }
        },
      }),
      getAccountFavorites: builder.query<Favorite[], void>({
        query: () => `management/post/favorite`,
        providesTags: (result) => [{ type: 'ManagementFavorites', id: 'LIST' }],
      }),
    }),
  })

export const {
  useAddStoreMutation,
  useCreatePostMutation,
  useDeletePostMutation,
  useGetAccountFavoritesQuery,
  useGetManagemenPostContributionQuery,
  useGetManagementMetricQuery,
  useGetManagementNewClientQuery,
  useGetManagementPostQuery,
  useGetManagementTopClientQuery,
  useGetStoreQuery,
  useLazyGetManagementPostQuery,
  useListStorePostsQuery,
  useListStoresQuery,
  useToggleFavoritePostMutation,
  useUpdatePostMutation,
  useUpdateStoreMutation,
  useUserListPostsQuery,
} = managementApiSlice

export type StoreFormDataValues = {
  title: string
  banner: string
  logo: string
}

export type PostFormDataValues = {
  title: string
  description: string
  store: number
  category: number
  address: string
  latitude: number
  longitude: number
  price: number
  duration: number
  availableFromTime: Dayjs | null
  availableToTime: Dayjs | null
}

function sanitizePost(data: Record<string, any>) {
  // Create a FormData object to handle multipart/form-data
  const formData = new FormData()

  Object.entries(data).forEach(([key, value]) => {
    if (key === 'days_of_week') {
      value = JSON.stringify(value.map((day: any) => day.id))
    }
    if (key === 'gps_lat' || key === 'gps_lng') {
      value = parseFloat(value).toFixed(8)
    }

    if (key === 'postimage_set' && Array.isArray(value)) {
      value.forEach((file, index) => {
        if (file instanceof File) formData.append(`postimage_set[${index}]`, file)
      })
    } else {
      formData.append(key, typeof value === 'object' ? JSON.stringify(value) : value)
    }
  })

  console.log('formData', formData)
  return formData
}
