import {
  Avatar,
  Button,
  ButtonGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDeletePostMutation } from '~/redux/features/api/management-api-slice'

function cleanColumns(original_string: string) {
  const resultString = original_string.replace('_', ' ')
  return resultString
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export interface ActionButtonsProps {
  id: string
  Icon: React.ElementType
  onClick: () => void
}

export interface FavoriteActionsMap {
  [key: string]: ActionButtonsProps[]
}

const ReusableTableWithActions = ({
  data,
  actionPath,
  columns_to_hide = [],
  imageColumns = [],
  actionButtons,
}: {
  data: any[]
  actionPath: string
  columns_to_hide?: string[]
  imageColumns?: string[]
  actionButtons?: FavoriteActionsMap
}) => {
  const columns = Object.keys(data[0]).filter(
    (key) =>
      !(
        columns_to_hide.includes(key) ||
        (typeof data[0][key] === 'object' && data[0][key] !== null) ||
        Array.isArray(data[0][key])
      ),
  )
  const navigate = useNavigate()
  const [selectedPostId, setSelectedPostId] = useState(data[0].id)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [deletePost, { isLoading }] = useDeletePostMutation()

  let deleteEntityName = actionPath
  if (actionPath.substring(actionPath.length - 1) === 's') {
    deleteEntityName = actionPath.substring(0, actionPath.length - 1)
  }
  const handleOpenDialog = (postId: string) => {
    setSelectedPostId(postId)
    setIsDialogOpen(true)
  }

  const handleDeleteConfirm = async (id: number) => {
    try {
      await deletePost({ id })
      setIsDialogOpen(false)
    } catch (error) {
      console.error('Failed to delete post:', error)
    }
  }

  return (
    <TableContainer component={Paper} sx={{ width: '100%', height: '100%' }}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell sx={{ fontWeight: 'bold' }} key={cleanColumns(column)}>
                {cleanColumns(column)}
              </TableCell>
            ))}
            {actionButtons && (
              <TableCell sx={{ fontWeight: 'bold' }} key="actions">
                Actions
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((column) => (
                <TableCell key={column}>
                  {imageColumns.includes(column) ? (
                    <Avatar
                      src={row[column]}
                      alt={column}
                      sx={{
                        width: 60,
                        height: 60,
                        borderRadius: 2,
                      }}
                    />
                  ) : (
                    row[column]
                  )}
                </TableCell>
              ))}
              {actionButtons && (
                <TableCell key="actions" align="right">
                  <ButtonGroup size="small" aria-label="small button group">
                    {actionButtons[row.title].map((actionButton) => (
                      <Button key={actionButton.id} onClick={actionButton.onClick}>
                        <actionButton.Icon />
                      </Button>
                    ))}
                  </ButtonGroup>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ReusableTableWithActions
