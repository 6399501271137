import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

const ReusableTableSkeleton = () => {
  return (
    <TableContainer component={Paper} sx={{ width: 'stretch' }}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="stores table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Skeleton variant="text" sx={{ fontSize: '1.3rem', width: '70%' }} />
            </TableCell>
            <TableCell align="left">
              <Skeleton variant="text" sx={{ fontSize: '1.3rem', width: '70%' }} />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" sx={{ fontSize: '1.3rem', width: '70%' }} />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" sx={{ fontSize: '1.3rem', width: '70%' }} />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" sx={{ fontSize: '1.3rem', width: '70%' }} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[1, 2, 3].map((index) => (
            <TableRow
              key={index}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              <TableCell component="th" scope="row">
                <Skeleton variant="text" sx={{ fontSize: '1.3rem', width: '70%' }} />
              </TableCell>
              <TableCell align="left">
                <Skeleton variant="text" sx={{ fontSize: '1.3rem', width: '55%' }} />
              </TableCell>
              <TableCell align="right">
                <Skeleton variant="text" sx={{ fontSize: '1.3rem', width: 20, float: 'right' }} />
              </TableCell>
              <TableCell align="right">
                <Skeleton variant="text" sx={{ fontSize: '1.3rem', width: 80, float: 'right' }} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ReusableTableSkeleton
