import { type Crop } from 'react-image-crop'

export const canvas = {
  setCanvasImage: (img: HTMLImageElement, canvas: HTMLCanvasElement, crop: Crop) => {
    const ctx = canvas.getContext('2d')
    if (ctx) {
      // Ensure the canvas dimensions match the crop dimensions
      canvas.width = crop.width
      canvas.height = crop.height

      // Draw the cropped area onto the canvas
      ctx.drawImage(img, crop.x, crop.y, crop.width, crop.height, 0, 0, crop.width, crop.height)
    }
    return canvas
  },
  getFileFromCanvas: (canvas: HTMLCanvasElement) => {
    // Convert canvas content to Data URL with Jpeg format
    const dataURL = canvas.toDataURL('image/jpeg') // Change MIME type to 'image/jpeg'

    // Convert Data URL to Blob
    const byteString = atob(dataURL.split(',')[1])
    const arrayBuffer = new ArrayBuffer(byteString.length)
    const uint8Array = new Uint8Array(arrayBuffer)
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i)
    }
    const blob = new Blob([uint8Array], { type: 'image/jpeg' }) // Change MIME type to 'image/jpeg'

    // Create a File object
    const fileName = 'myImage.jpeg' // Set the desired file name
    return new File([blob], fileName, { type: 'image/jpeg' })
  },
}
