import { CalendarMonth, List } from '@mui/icons-material'

const management = [
  {
    to: '/management/calendar',
    label: 'ui.myProfile.calendar',
    icon: CalendarMonth,
    healthPractitioner: false,
  },
  { to: '/management/posts', label: 'ui.myProfile.posts', icon: List, healthPractitioner: true },
] as const

export default management
