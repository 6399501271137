import CameraIcon from '@mui/icons-material/Camera'
import { Box } from '@mui/material'
import { styled } from '@mui/system'
import ReactCrop, { ReactCropProps } from 'react-image-crop'

// Styled Components
export const MainWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: 280,
  flexDirection: 'column',
  position: 'relative',
  overflow: 'hidden',
  backgroundColor: theme.palette.grey[100],
  borderRadius: 1,
}))

export const AddonWrapper = styled(Box)({
  position: 'absolute',
  right: 0,
  display: 'flex',
})

export const CropWrapper = styled((props) => (
  <Box component={ReactCrop} {...props} />
))<ReactCropProps>({
  alignSelf: 'center',
  position: 'relative',
  maxHeight: '60vh',
})

export const InputWrapper = styled(Box)({
  position: 'absolute',
  width: '100%',
  height: '100%',
})

export const InputIconWrapper = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const InputIcon = styled(CameraIcon)(({ theme }) => ({
  position: 'absolute',
  color: theme.palette.primary.dark,
}))

export const CanvasWrapper = styled(Box)({
  display: 'flex',
  position: 'relative',
})

export const ActionsWrapper = styled(Box)({
  position: 'absolute',
  right: 0,
  cursor: 'pointer',
  display: 'flex',
})

export const DialogWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  zIndex: 9999,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
})

export const SavedWrapper = styled(Box)({
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  opacity: 0,
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    opacity: 1,
    transition: 'opacity .15s',
  },
})

export const PreviewImage = styled('img')({
  width: '100%',
  objectFit: 'cover',
  height: '100%',
})
