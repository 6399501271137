import { Close } from '@mui/icons-material'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import { useCallback, useEffect } from 'react'
import classNames from 'react-day-picker/style.module.css'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { usePostFilters } from '~/hooks/usePostFilters'
import { useCreateBookingMutation } from '~/redux/features/api/booking-api-slice'
import { useLazyGetCalendarQuery, usePrefetchCalendar } from '~/redux/features/api/calendarApiAlice'
import { selectAuth } from '~/redux/features/auth/auth-slice'
import {
  resetCalendar,
  selectCalendar,
  updateCalendarData,
  updateCalendarDate,
  updateCalendarOpen,
} from '~/redux/features/calendar/reducer'
import { showToast } from '~/redux/features/toastSlice'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import {
  Calendar,
  DialogContent,
  Paper,
  ScheduleButton,
  ScheduleContainer,
} from './BasicDateCalendar.styled'
import { generateAvailableSlots, processUnavailability } from './utils'

const BasicDateCalendar = () => {
  const prefetchCalendarAvailability = usePrefetchCalendar('getCalendar')
  const { authenticated, ...user } = useAppSelector(selectAuth)
  const {
    data: calendarData,
    date: calendarDate,
    open: calendarOpen,
    isLoading: calendarIsLoading,
  } = useAppSelector(selectCalendar)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { open, hour, day, month, year, postId, setFilters } = usePostFilters()

  const [getCalendar] = useLazyGetCalendarQuery()

  useEffect(() => {
    if (open && !calendarData.admin.name.length && !isNaN(Number(calendarDate.postId))) {
      console.log(open && !calendarData.admin.name.length && !isNaN(Number(calendarDate.postId)))

      getCalendar({ ...calendarDate }).then(({ isSuccess, isError, data }) => {
        if (isSuccess) {
          dispatch(updateCalendarData({ ...data }))
        }
      })
    }
  }, [open, calendarDate])

  const handleMouseEnterPrev = async () => {
    let prevMonth = month - 1
    let prevYear = year
    if (prevMonth < 0) {
      prevMonth = 11
      prevYear -= 1
    }
    const args = { postId, year: prevYear, month: prevMonth }
    await prefetchCalendarAvailability({ ...args })
  }

  const handleMouseEnterNext = async () => {
    let nextMonth = month + 1
    let nextYear = year
    if (nextMonth > 11) {
      nextMonth = 0
      nextYear += 1
    }
    const args = { postId, year: nextYear, month: nextMonth }

    await prefetchCalendarAvailability({ ...args })
  }

  const handleClose = () => setFilters({ open: false })

  const handleTimeSlot = (start_time: string) => setFilters({ hour: start_time })

  // Handle calendar changes
  const handleSelectDay = (date: Date) => setFilters({ day: date.getDate() })

  const handleMonthChange = useCallback(
    async (newMonth: Date) => {
      const year = newMonth.getFullYear()
      const month = Number(String(newMonth.getMonth() + 1).padStart(2, '0'))

      setFilters({ month, year })

      if (!calendarDate?.postId) {
        console.error('No postId found in data', calendarData)
        return void 0
      }

      dispatch(updateCalendarDate({ postId: calendarDate.postId, year, month }))
    },
    [calendarData, dispatch, setFilters],
  )

  // Formatted slot (YYYY-MM-DD)
  const slot = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`

  // Processing unavailability's
  const disabled = processUnavailability(calendarData?.admin?.unavailabilities)
  // Generate disabled days from the processed unavailability's
  const disabledDays = Object.keys(disabled).map((date) => new Date(date))
  // Generate available slots based on the selected year and month
  const schedule = generateAvailableSlots({ year, month }, calendarData)
  // Highlight days with available slots
  const highlightedDays = Object.keys(schedule || {}).map((date) => new Date(date))

  const [bookSlot, { reset, isLoading, isSuccess, isError, error }] = useCreateBookingMutation()

  const isDoctor = user?.account_detail?.account_type === 'doctor'
  const handleBook = async () => {
    if (!hour) {
      console.error('ERROR: No time slot selected')
      return
    }

    if (!authenticated) {
      console.error('ERROR: User is not authenticated')
      dispatch(resetCalendar())
      navigate('/login', { state: location })
      return
    }

    if (isDoctor) {
      console.error('ERROR: Doctor cannot book a slot')
      return
    }
    console.log(calendarData)
    await bookSlot({ date: slot, post: calendarDate.postId as number, start_time: hour })
  }

  useEffect(() => {
    if (isDoctor && open) {
      console.log('Doctor cannot book a slot')
      handleClose()
      setFilters({ open: false })
      dispatch(updateCalendarOpen(false))
      dispatch(showToast({ message: t('ui.calendar.isDoctorMessage'), severity: 'error' }))
    }
  }, [isDoctor, open])

  return (
    <Dialog
      maxWidth="xl"
      open={calendarOpen || open || false}
      onClose={handleClose}
      sx={{ maxWidth: 'fit--content' }}
      slotProps={{
        backdrop: {
          style: {
            backdropFilter: 'blur(4px)',
          },
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {t('ui.calendar.title', 'Calendar')}
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <Close />
      </IconButton>

      <DialogContent>
        <Paper sx={{ display: 'grid', gap: 2 }}>
          <Box sx={{ display: 'flex', gap: 2, overflow: 'hidden' }}>
            <Calendar
              required
              classNames={classNames}
              mode="single"
              numberOfMonths={1}
              showOutsideDays
              selected={new Date(slot)}
              month={new Date(slot)}
              disabled={[{ before: new Date() }, ...disabledDays]}
              modifiers={{ highlight: highlightedDays }}
              modifiersClassNames={{ highlight: 'highlighted' }}
              onDayClick={handleSelectDay}
              onMonthChange={handleMonthChange}
              sx={(theme) => ({
                padding: theme.spacing(2),
                borderRadius: theme.shape,
                border: '1px solid var(--border-color)',
              })}
              components={{
                PreviousMonthButton: (props) => {
                  return (
                    <IconButton
                      onClick={(event) => props?.onClick?.(event)}
                      onMouseEnter={handleMouseEnterPrev}
                    >
                      <ChevronLeft />
                    </IconButton>
                  )
                },
                NextMonthButton: (props) => {
                  return (
                    <IconButton
                      onClick={(event) => props?.onClick?.(event)}
                      onMouseEnter={handleMouseEnterNext}
                    >
                      <ChevronRight />
                    </IconButton>
                  )
                },
              }}
            />

            {slot && schedule && (
              <Paper sx={{ flexDirection: 'column', gap: 1 }}>
                <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                  {t('ui.calendar.availability', 'Availability')}
                </Typography>

                <Box sx={{ position: 'relative', overflow: 'auto', height: '100%' }}>
                  <ScheduleContainer>
                    {schedule?.[slot]?.map(({ start_time }: { start_time: string }) => {
                      const isDisabled = new Date(`${slot}T${start_time}`) < new Date()
                      return (
                        <ScheduleButton
                          key={start_time}
                          className={hour === start_time ? 'active' : undefined}
                          onClick={() => !isDisabled && handleTimeSlot(start_time)}
                          disabled={isDisabled}
                        >
                          {start_time}
                        </ScheduleButton>
                      )
                    })}
                  </ScheduleContainer>
                </Box>
              </Paper>
            )}
          </Box>

          {!isError && (
            <Button disableElevation variant="contained" onClick={handleBook} disabled={isLoading}>
              {isLoading ? <CircularProgress size={24} /> : t('ui.calendar.book', 'Book')}
            </Button>
          )}

          {isSuccess && <Typography>Booking successful!</Typography>}

          {isError && (
            <>
              {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment 
              // @ts-ignore */}
              <Typography color="error">{error.data.detail}</Typography>

              <Button variant="outlined" onClick={reset}>
                {t('reset', 'Reset')}
              </Button>
            </>
          )}
        </Paper>
      </DialogContent>

      {calendarIsLoading && (
        <Box
          sx={{
            position: 'absolute',
            alignItems: 'center',
            backdropFilter: 'blur(16px)',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            width: '100%',
            zIndex: 2,
          }}
        >
          <CircularProgress size={42} />
        </Box>
      )}
    </Dialog>
  )
}

export default BasicDateCalendar
