import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

export type UsePostsSchema = z.infer<ReturnType<typeof usePostsSchema>>

export const usePostsForm = (defaultValues?: Partial<UsePostsSchema>) => {
  const schema = usePostsSchema()

  return useForm({
    mode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues: {
      category: 0,
      subcategory: 0,
      title: '',
      description: '',
      days_of_week: [
        { id: 1, label: 'Monday' },
        { id: 2, label: 'Tuesday' },
        { id: 3, label: 'Wednesday' },
        { id: 4, label: 'Thursday' },
        { id: 5, label: 'Friday' },
      ],
      price: 0,
      duration_minutes: 30,
      address: '',
      availabilities: [
        { start_time: '09:00', end_time: '12:00' },
        { start_time: '14:00', end_time: '18:00' },
      ],
      gps_lat: 0,
      gps_lng: 0,
      currency_code: 'EUR',
      ...defaultValues,
    },
  })
}

const usePostsSchema = () => {
  return z.object({
    address: z
      .string()
      .min(3, { message: t('ui.form.fieldRequired', { value: t('ui.form.address') }) }),
    availabilities: z.array(z.object({ start_time: z.string(), end_time: z.string() })),
    category: z.coerce.number().refine((value) => value > 0, {
      message: t('ui.form.fieldRequired', { value: 'Category' }),
    }),
    description: z.string().min(2, { message: 'Text must be at least 2 characters' }),
    duration_minutes: z.coerce
      .number()
      .min(15, 'Minimum duration is 15 minutes')
      .max(60, 'Maximum duration is 60 minutes'),
    gps_lat: z.coerce.number(),
    gps_lng: z.coerce.number(),
    postimage_set: z.array(z.instanceof(File)),
    price: z.coerce.number().min(1, { message: 'Price must be at least 1' }),
    days_of_week: z.array(z.object({ id: z.number().min(1).max(7), label: z.string() })),
    subcategory: z.coerce.number().refine((value) => value > 0, {
      message: t('ui.form.fieldRequired', { value: 'Subcategory' }),
    }),
    title: z.string().min(2, { message: 'Text must be at least 2 characters' }),
  })
}
