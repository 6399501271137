import ListResponse from '~/helpers/types/common'
import { ListPost, Post } from '~/helpers/types/post'
import { UserProfile } from '~/helpers/types/user-profile'
import { urls } from '~/helpers/utils/urls'
import { apiSlice } from './api-slice'

export type PostFormDataValues = {
  title: string
  description: string
  price: number
  address: string
  city: string
  postal_code: string
  category?: number | string
  country: string
  images?: any[]
  is_favorite: boolean
}

interface GetPostInput {
  id: string
}

export type PostPriceRange = {
  min_price: number
  max_price: number
}

export type PostPractitionersProps = {
  category?: number
  subcategory?: number
}

export type PostPractitioners = {
  id: number
  name: string
}

type Params = {
  page: number
  limit: number
  practitioner?: number
  category?: number
  subcategory?: number
  priceMin?: number
  priceMax?: number
  sortDistance?: boolean
  latitude?: number
  longitude?: number
  searchQuery?: string
}

export const postsApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['Post'] }).injectEndpoints({
  endpoints: (builder) => ({
    addPost: builder.mutation<any, FormData>({
      query: (body) => {
        return {
          url: '/posts/new',
          method: 'POST',
          body,
        }
      },
    }),
    getPost: builder.query<Post, GetPostInput>({
      query: (args) => {
        const { id } = args
        return `/post/${id}`
      },
      providesTags: (result, error, { id }) => [{ type: 'Post', id }],
    }),
    getPostSeller: builder.query<UserProfile, GetPostInput>({
      query: (args) => {
        const { id } = args
        return `/posts/${id}/seller`
      },
      transformResponse: (response: { id: string; seller: UserProfile }): UserProfile => {
        return response.seller
      },
    }),
    getPostRelatedPosts: builder.query<Post[], GetPostInput>({
      query: (args) => {
        const { id } = args
        return `/posts/${id}/related`
      },
      transformResponse: (response: ListResponse<Post>): Post[] => {
        return response.results
      },
    }),
    listPosts: builder.query<ListResponse<ListPost>, Params>({
      query: (args) => {
        const { searchQuery, ...params } = args
        const filteredParams = filterParams(params)

        return {
          url: 'post',
          params: filteredParams,
        }
      },
    }),
    postPractitioners: builder.query<PostPractitioners[], PostPractitionersProps | void>({
      query: (args) => {
        return `post/practitioners${urls.argsToParams({ ...args })}`
      },
    }),
    categoryPostPriceRange: builder.query<PostPriceRange, { category?: number }>({
      query: (args) => {
        const { category } = args
        return `post/price-range/${urls.argsToParams({
          category,
        })}`
      },
    }),
  }),
})

export const {
  useAddPostMutation,
  useCategoryPostPriceRangeQuery,
  useGetPostQuery,
  useGetPostRelatedPostsQuery,
  useGetPostSellerQuery,
  useLazyGetPostQuery,
  useListPostsQuery,
  usePostPractitionersQuery,
  usePrefetch: usePrefetchPosts,
} = postsApiSlice

type ParamKey = keyof Params
// Function to filter out parameters with a value of -1
function filterParams(params: Params): Partial<Params> {
  return Object.keys(params).reduce((acc, key) => {
    const typedKey = key as ParamKey
    const value = params[typedKey]
    if (value !== -1) {
      return { ...acc, [key]: value }
    }
    return acc
  }, {})
}
