import ClearIcon from '@mui/icons-material/Clear'
import CropIcon from '@mui/icons-material/Crop'
import SaveIcon from '@mui/icons-material/Save'
import { Box, IconButton, SxProps, Theme } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { PixelCrop, centerCrop, makeAspectCrop, type Crop } from 'react-image-crop'
import { canvas } from '~/helpers/utils/canvas'
import {
  AddonWrapper,
  CropWrapper,
  DialogWrapper,
  InputIcon,
  InputIconWrapper,
  InputWrapper,
  MainWrapper,
  PreviewImage,
  SavedWrapper,
} from './InputImageCrop.styled'
import LoadingSpinner from './LoadingSpinner'
import Saving from './Saving'

const STATUS = {
  PRISTINE: 'PRISTINE',
  CROPPING: 'CROPPING',
  LOADING_IMAGE: 'LOADING_IMAGE',
  SAVED: 'SAVED',
  SAVING_IMAGE: 'SAVING_IMAGE',
} as const

type Status = keyof typeof STATUS

const InputImageCrop = ({
  startingValue,
  setImageCallback,
  setImageResetCallback,
  aspect,
}: {
  startingValue?: string
  aspect?: number
  setImageResetCallback: () => void | undefined
  setImageCallback: (file: File) => void | undefined
  sx?: SxProps<Theme>
}) => {
  const [crop, setCrop] = useState<Crop>()
  const [currentCrop, setCurrentCrop] = useState<PixelCrop>()
  const [imgPreviewSource, setImgPreviewSource] = useState(startingValue)
  const [imgSource, setImgSource] = useState('')
  const [status, setStatus] = useState<Status>(STATUS.PRISTINE)
  const imgPreviewRef = useRef<HTMLImageElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    if (startingValue) {
      setImgPreviewSource(startingValue)
      setStatus(STATUS.SAVED)
    }
  }, [startingValue])

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setStatus(STATUS.LOADING_IMAGE)

      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()

      reader.onload = () => {
        setStatus(STATUS.CROPPING)
        return setImgSource(reader.result?.toString() || '')
      }

      reader.readAsDataURL(e.target.files[0])
    }
  }

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget
    setCrop(centerAspectCrop(width, height, aspect ? aspect : width / height))
  }

  const resetView = () => {
    setStatus(STATUS.PRISTINE)
    setImgSource('')
    setImgPreviewSource(undefined)
    setImageResetCallback()
  }

  const editImage = () => {
    setStatus(STATUS.CROPPING)
  }

  const saveImage = () => {
    if (imgRef.current && currentCrop) {
      setStatus(STATUS.SAVING_IMAGE)

      const myCanvas = document.createElement('canvas')

      const canvasElement = canvas.setCanvasImage(imgRef.current, myCanvas, currentCrop)

      if (canvasElement) {
        const file: File = canvas.getFileFromCanvas(canvasElement)
        setImageCallback(file)
        setPreviewImage(file)
        setStatus(STATUS.SAVED)
      }
    }
  }

  const setPreviewImage = (file: File) => {
    const reader = new FileReader()
    reader.onload = () => {
      setImgPreviewSource(reader.result?.toString() || '')
    }
    reader.readAsDataURL(file)
  }

  const saveButton = (
    <IconButton aria-label="save" color="success" onClick={saveImage}>
      <SaveIcon fontSize="large" sx={{ border: 3, borderRadius: '50%', padding: 1 }} />
    </IconButton>
  )

  const resetButton = (
    <IconButton aria-label="reset" color="error" onClick={resetView}>
      <ClearIcon fontSize="large" sx={{ border: 3, borderRadius: '50%', padding: 1 }} />
    </IconButton>
  )

  const cropAddon = (
    <AddonWrapper className="🍌">
      {saveButton} {resetButton}
    </AddonWrapper>
  )

  const savedElement = (
    <>
      <SavedWrapper>
        <IconButton aria-label="edit" color="info" onClick={editImage} sx={{ cursor: 'pointer' }}>
          <CropIcon fontSize="large" sx={{ border: 3, borderRadius: '50%', padding: 1 }} />
        </IconButton>

        <IconButton aria-label="reset" color="error" onClick={resetView} sx={{ cursor: 'pointer' }}>
          <ClearIcon fontSize="large" sx={{ border: 3, borderRadius: '50%', padding: 1 }} />
        </IconButton>
      </SavedWrapper>

      <PreviewImage ref={imgPreviewRef} alt="preview" src={imgPreviewSource} />
    </>
  )

  const showSavedImage =
    status === STATUS.SAVED || (status === STATUS.CROPPING && imgPreviewSource !== '')

  const showDialog = status === STATUS.CROPPING || status === STATUS.LOADING_IMAGE

  return (
    <MainWrapper>
      {status === STATUS.PRISTINE && (
        <InputWrapper>
          <Box component="label" htmlFor="pickImage" sx={{ cursor: 'pointer' }}>
            <InputIconWrapper>
              <InputIcon />
            </InputIconWrapper>

            <Box
              id="pickImage"
              accept="image/heic, image/png, image/jpeg, image/webp"
              aria-hidden={true}
              component="input"
              display="none"
              onChange={onSelectFile}
              type="file"
            />
          </Box>
        </InputWrapper>
      )}

      {status === STATUS.SAVING_IMAGE && <Saving />}

      {status === STATUS.SAVED && showSavedImage && savedElement}

      {showDialog && (
        <DialogWrapper>
          {status === STATUS.CROPPING && (
            <CropWrapper
              renderSelectionAddon={() => cropAddon}
              keepSelection={true}
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCurrentCrop(c)}
              aspect={aspect}
            >
              <img ref={imgRef} alt="Crop me" src={imgSource} onLoad={onImageLoad} />
            </CropWrapper>
          )}
          {status === STATUS.LOADING_IMAGE && (
            <Box height="100%" display="flex" justifyContent="center" alignItems="center">
              <LoadingSpinner size={250} />
            </Box>
          )}
        </DialogWrapper>
      )}
    </MainWrapper>
  )
}

export default InputImageCrop

const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

const fetchImage = async (imageUrl: string, callback: (...args: any) => any) => {
  try {
    const response = await fetch(imageUrl)
    if (response.ok) {
      const dataUrl = await response.blob()
      const reader = new FileReader()

      reader.onload = () => {
        callback(reader.result?.toString() || '')
      }

      reader.readAsDataURL(dataUrl)
    } else {
      console.error('Failed to fetch image')
    }
  } catch (error) {
    console.error('Error fetching image:', error)
  }
}
