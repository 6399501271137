import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {
  Button,
  ButtonGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DeleteConfirmationDialog from '~/components/Dialog/DialogConfirmation'
import translationLink from '~/helpers/utils/translationLink'
import {
  useDeletePostMutation,
  useUserListPostsQuery,
} from '~/redux/features/api/management-api-slice'

const ReusableTable = ({
  data,
  actionPath,
  columns_to_hide = [],
  withActions = true,
}: {
  data: any[]
  actionPath: string
  columns_to_hide?: string[]
  withActions?: boolean
}) => {
  // Add this query to allow cache invalidation
  const { refetch: refetchUserListPosts } = useUserListPostsQuery({})
  const columns = Object.keys(data[0]).filter(
    (key) =>
      !(
        columns_to_hide.includes(key) ||
        (typeof data[0][key] === 'object' && data[0][key] !== null) ||
        Array.isArray(data[0][key])
      ),
  )
  const navigate = useNavigate()
  const [selectedPostId, setSelectedPostId] = useState(data[0].id)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [deletePost] = useDeletePostMutation()

  let deleteEntityName = actionPath
  if (actionPath.substring(actionPath.length - 1) === 's') {
    deleteEntityName = actionPath.substring(0, actionPath.length - 1)
  }
  const handleOpenDialog = (postId: string) => {
    setSelectedPostId(postId)
    setIsDialogOpen(true)
  }

  const handleDeleteConfirm = useCallback(async (id: number) => {
    try {
      await deletePost({ id }).unwrap()
      await refetchUserListPosts()
    } catch (error) {
      console.error('Failed to delete post:', error)
    } finally {
      setIsDialogOpen(false)
    }
  }, [])

  return (
    <TableContainer component={Paper} sx={{ width: '100%', height: '100%' }}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell sx={{ fontWeight: 'bold' }} key={cleanColumns(column)}>
                {cleanColumns(column)}
              </TableCell>
            ))}
            {withActions && (
              <TableCell sx={{ fontWeight: 'bold' }} key="actions">
                Actions
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((column) => (
                <TableCell key={column}>{row[column]}</TableCell>
              ))}
              {withActions && (
                <TableCell key="actions" align="right">
                  <ButtonGroup size="small" aria-label="small button group">
                    <Button
                      key="edit"
                      onClick={() => {
                        navigate(
                          translationLink(`/management/${actionPath}/${row.id}/edit?debug`),
                          {
                            state: { data: row },
                          },
                        )
                      }}
                    >
                      <EditIcon />
                    </Button>
                    <Button key="delete" onClick={() => handleOpenDialog(row.id)}>
                      <DeleteIcon />
                    </Button>
                  </ButtonGroup>
                </TableCell>
              )}
            </TableRow>
          ))}

          {withActions && (
            <DeleteConfirmationDialog
              open={isDialogOpen}
              handleClose={() => setIsDialogOpen(false)}
              handleConfirm={handleDeleteConfirm}
              deleteEntityName={deleteEntityName}
              postId={selectedPostId}
            />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ReusableTable

function cleanColumns(original_string: string) {
  const resultString = original_string.replace(/_/g, ' ')
  return resultString
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
