import Typography from '@mui/material/Typography'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PostForm from '~/components/Posts/PostForm'
import Col from '~/components/UI/Grid/Col'
import Row from '~/components/UI/Grid/Row'
import Section from '~/components/UI/Section'
import { usePostsForm } from '~/hooks/usePostsForm'

const Page = () => {
  const { t } = useTranslation()
  const postForm = usePostsForm()

  return (
    <Section padding={[{ target: 'top', value: 'small' }]}>
      <Row>
        <Col xs={12}>
          <Typography variant="h5" gutterBottom>
            {t('ui.service.create', 'New Service')}
          </Typography>

          <FormProvider {...postForm}>
            <PostForm />
          </FormProvider>
        </Col>
      </Row>
    </Section>
  )
}

export default Page
