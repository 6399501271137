import i18n from '~/i18n'
import { DEFAULT_LANG } from '~/locales'

const translationLink = (path = '') => {
  // Get the current language
  const currentLanguage = i18n.language

  if (['/'].includes(path)) path = ''

  const newPath = `${currentLanguage === DEFAULT_LANG ? '' : '/'.concat(currentLanguage)}/${path}`
  const result = newPath.replace(/\/\//g, '/')

  return result
}

export default translationLink
