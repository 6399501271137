import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  Backdrop,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Slider,
  Stack,
  Typography,
} from '@mui/material'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import customParserFormat from 'dayjs/plugin/customParseFormat'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import translationLink from '~/helpers/utils/translationLink'
import { UsePostsSchema } from '~/hooks/usePostsForm'
import { MapLocation } from '~/hooks/useSearchMapLocations'
import {
  useCreatePostMutation,
  useUpdatePostMutation,
} from '~/redux/features/api/management-api-slice'
import {
  Category,
  useGetCategoriesWithSubCategoriesQuery,
} from '~/redux/features/categories/categories-slice'
import { SubCategory } from '~/redux/features/subcategories'
import { showToast } from '~/redux/features/toastSlice'
import { useAppDispatch } from '~/redux/hooks'
import Form, { FormField } from '../Form'
import LocationPicker from './LocationPicker'
import PostImageGalleryForm from './PostImageGalleryForm'

dayjs.extend(customParserFormat)

type WeekDays = { id: number; label: string }[]
type WeekDay = WeekDays[number]

const PostForm = () => {
  const { id } = useParams()
  const { i18n } = useTranslation()
  const { search, state } = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [daysOfWeek, setDaysOfWeek] = useState<WeekDays>([])
  const [selectedDays, setSelectedDays] = useState<WeekDays>([])

  const [createPost, { isLoading: isCreatePostLoading }] = useCreatePostMutation()
  const [updatePost, { isLoading: isUpdatePostLoading }] = useUpdatePostMutation()

  const {
    control,
    getValues,
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<UsePostsSchema>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'availabilities',
  })
  const { address, gps_lat, gps_lng, category, duration_minutes: duration, days_of_week } = watch()

  useEffect(() => {
    const validKeys = Object.keys(control?._options?.defaultValues || {})

    if (state?.data && id) {
      Object.entries(state.data).forEach(([key, value]) => {
        if (!validKeys.includes(key)) return

        const k = key as keyof UsePostsSchema
        const v = value as any
        if (['category', 'subcategory'].includes(k)) {
          setValue(k, v)
        } else if (days_of_week && k === 'days_of_week') {
          setValue(
            k,
            daysOfWeek.filter((day) => v.includes(day.id)),
          )
        } else if (k === 'address') {
          setValue(k, v)
        } else setValue(k, v)
      })
    }
  }, [daysOfWeek, state?.data])

  const [allCategories, setAllCategories] = useState<Category[]>([])
  const [allSubcategories, setAllSubcategories] = useState<SubCategory[]>([])

  const { data: categories, isLoading: isCategoriesLoading } =
    useGetCategoriesWithSubCategoriesQuery({})

  useEffect(() => {
    if (!isCategoriesLoading && categories) {
      setAllCategories(categories.map(({ subcategories, ...category }) => category))
      setAllSubcategories(categories.flatMap(({ subcategories }) => subcategories ?? []))
      setValue('subcategory', getValues('subcategory') ?? 0)
    }
  }, [categories, getValues, isCategoriesLoading, setValue])

  useEffect(() => {
    setAllSubcategories(
      categories
        ?.flatMap(({ subcategories }) => subcategories ?? [])
        .filter(({ category_id }) => category_id === category) ?? [],
    )
  }, [category, categories, setValue])

  const [startingLocation, setStartingLocation] = useState<MapLocation>({
    address,
    latitude: gps_lat,
    longitude: gps_lng,
  })
  useEffect(() => {
    const { address, gps_lat, gps_lng } = state?.data || {}
    if (address && gps_lat && gps_lng) {
      setStartingLocation({ address, latitude: gps_lat, longitude: gps_lng })
    }
  }, [state?.data, address, gps_lat, gps_lng])

  function handlePostSuccess() {
    navigate(translationLink('/management/posts'))
  }
  const onSubmit = async (data: UsePostsSchema) => {
    try {
      if (id) {
        await updatePost({ id, ...data }).unwrap()
      } else {
        await createPost(data).unwrap()
      }
      handlePostSuccess()
    } catch (error) {
      // Handle specific error types
      if (error instanceof Error) {
        console.error('API Error:', error.message)
        // You could also set an error state or show a notification here
      } else {
        console.error('Unknown error:', error)
      }
      dispatch(
        showToast({
          severity: 'error',
          message: t('ui.api.error', 'Something went wrong, please try again!'),
        }),
      )
    }
  }

  useEffect(() => {
    const lang = i18n.language || 'en'
    const translatedDays = Array.from({ length: 7 }, (_, i) => ({
      id: i + 1, // Assign ID from 1 to 7 (Monday = 1, Sunday = 7)
      label: new Intl.DateTimeFormat(lang, { weekday: 'long' }).format(new Date(1970, 0, i + 5)),
    }))

    setDaysOfWeek(translatedDays)
  }, [i18n.language])

  useEffect(() => {
    const daysOfWeek = days_of_week?.filter((day): day is WeekDay => !!day && !!day?.id) ?? []
    setSelectedDays(daysOfWeek)
  }, [days_of_week])

  const handleDaySelection = (day: WeekDay) => {
    const selectedDays = getValues('days_of_week') || []
    const isDaySelected = selectedDays.some((d) => d.id === day.id)

    const newSelectedDays = isDaySelected
      ? selectedDays.filter((d) => d.id !== day.id)
      : [...selectedDays, day]

    setValue('days_of_week', newSelectedDays)
  }

  const handleAddressChange = (address: string) => {
    setValue('address', address)
  }

  const handleCoordinatesChange = (latitude: number, longitude: number) => {
    setValue('gps_lat', latitude)
    setValue('gps_lng', longitude)
  }

  const isLoading = isCategoriesLoading || isUpdatePostLoading || isCreatePostLoading

  const postImageSet = state?.data?.postimage_set ?? []
  const [openDialog, setOpenDialog] = useState(false)
  const handleToggleDialog = () => setOpenDialog((prev) => !prev)
  const queryParams = new URLSearchParams(search)
  const showDebug = queryParams.has('debug')

  return (
    <>
      {showDebug && (
        <Button variant="contained" color="primary" onClick={handleToggleDialog}>
          View Form Data
        </Button>
      )}

      <Dialog open={openDialog} onClose={handleToggleDialog} maxWidth="md" fullWidth>
        <DialogTitle>Form Data</DialogTitle>
        <DialogContent>
          <Typography
            component="pre"
            sx={{
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-all',
            }}
          >
            <code>{JSON.stringify(getValues?.(), null, 2)}</code>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {isLoading && (
        <Box
          component={Backdrop}
          open={true}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer,
            backdropFilter: 'blur(8px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}
        />
      )}

      <Form noValidate onSubmit={handleSubmit(onSubmit)} register={register}>
        <Grid container spacing={2}>
          {/* Category | Subcategory */}
          <Grid item xs={12}>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 0, md: 2 }}>
              <FormField
                fullWidth
                select
                disabled={isCategoriesLoading}
                label={t('ui.category', 'Category')}
                name="category"
                errors={errors}
                register={register}
                value={watch('category')}
                defaultValue={
                  allCategories.filter(({ id }) => id === state?.data?.category) ?? allCategories[0]
                }
              >
                <MenuItem value={0} selected disabled>
                  Pick Category...
                </MenuItem>
                {allCategories?.map(({ id, label }) => (
                  <MenuItem key={id} data-value={id} value={id}>
                    {label}
                  </MenuItem>
                ))}
              </FormField>

              <FormField
                fullWidth
                select
                disabled={isCategoriesLoading}
                label={t('ui.subcategory', 'Subcategory')}
                name="subcategory"
                errors={errors}
                register={register}
                value={watch('subcategory')}
                defaultValue={
                  allSubcategories.filter(({ id }) => id === state?.data?.subcategory) ??
                  allSubcategories[0]
                }
              >
                <MenuItem value={0} selected disabled>
                  Pick Subcategory...
                </MenuItem>
                {allSubcategories?.map(({ id, label }) => (
                  <MenuItem key={id} value={id}>
                    {label}
                  </MenuItem>
                ))}
              </FormField>
            </Stack>
          </Grid>

          {/* Input Text */}
          <Grid item xs={12}>
            <FormField
              required
              errors={errors}
              label={t('ui.title', 'Title')}
              name="title"
              register={register}
            />
          </Grid>

          {/* Text Area */}
          <Grid item xs={12}>
            <FormField
              required
              multiline
              errors={errors}
              label={t('ui.description', 'Description')}
              name="description"
              minRows={4}
              register={register}
            />
          </Grid>

          {/* Week days */}
          <Grid item xs={12}>
            <Typography variant="h6">Select Days</Typography>

            <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
              {daysOfWeek?.map((day) => {
                // check from array selectedDays if it includes day.id
                // if it does, then set the color to primary
                const checked = selectedDays.some((sd) => sd.id === day.id)
                return (
                  <Chip
                    key={day.id}
                    label={day.label}
                    clickable
                    color={checked ? 'primary' : 'default'}
                    onClick={() => handleDaySelection(day)}
                    style={{ marginLeft: 0 }}
                  />
                )
              })}
            </Stack>

            {errors?.days_of_week && (
              <FormHelperText error required sx={{ mt: 0.5, mr: 1.75, ml: 1.75 }}>
                {errors?.days_of_week?.message}
              </FormHelperText>
            )}
          </Grid>

          {/* Pricing | Duration */}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormField
                  fullWidth
                  type="number"
                  label="Price"
                  name="price"
                  errors={errors}
                  register={register}
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl sx={{ width: 'stretch' }}>
                  <FormLabel sx={{ fontSize: 'small' }}>
                    {t('ui.form.duration_minutes', `Duration ${duration} (minutes)`, {
                      duration,
                    })}
                  </FormLabel>
                  <Controller
                    name="duration_minutes"
                    control={control}
                    render={({ field }) => (
                      <Slider
                        marks={[
                          { value: 15, label: '15' },
                          { value: 30, label: '30' },
                          { value: 45, label: '45' },
                          { value: 60, label: '60' },
                        ]}
                        value={field.value}
                        min={15}
                        max={60}
                        step={15}
                        valueLabelDisplay="auto"
                        onChange={(e, value) => field.onChange(value)}
                        sx={{
                          '&.MuiSlider-root > span': {
                            '&:nth-of-type(4)': { marginLeft: '10px' },
                            '&:nth-last-of-type(2)': {
                              translate: '-10px 0',
                            },
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          {/* Availabilities */}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box>
                  <Typography variant="h6" component="span">
                    {t('ui.form.workingSchedule', 'Working Schedule')}{' '}
                  </Typography>
                  <Button
                    disableElevation
                    variant="contained"
                    color="info"
                    onClick={() => append({ start_time: '', end_time: '' })}
                    sx={{ minWidth: 'unset', borderRadius: '50%', p: 1.25 }}
                  >
                    <AddIcon fontSize="small" />
                  </Button>
                </Box>

                {!fields.length && <Typography color="error">No schedule defined yet</Typography>}
              </Grid>

              {fields.map((field, index) => (
                <Grid key={field.id} item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack direction="row" spacing={2}>
                      <Controller
                        control={control}
                        name={`availabilities.${index}.start_time`}
                        render={({ field: startField }) => (
                          <TimePicker
                            ampm={false}
                            label="Start"
                            value={dayjs(startField?.value, 'HH:mm') ?? null}
                            timeSteps={{ hours: 1, minutes: duration, seconds: duration }}
                            minutesStep={duration}
                            views={['hours', 'minutes']}
                            onChange={(newValue) => {
                              startField.onChange(newValue?.format('HH:mm') ?? '')
                            }}
                            slotProps={{
                              textField: {
                                required: true,
                                size: 'small',
                                margin: 'normal',
                                sx: { flex: '1 0 0' },
                              },
                            }}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name={`availabilities.${index}.end_time`}
                        render={({ field: endField }) => {
                          const startTime = dayjs(
                            getValues(`availabilities.${index}.start_time`),
                            'HH:mm',
                          )

                          return (
                            <TimePicker
                              ampm={false}
                              label="End"
                              value={dayjs(endField?.value, 'HH:mm') ?? null}
                              timeSteps={{ hours: 1, minutes: duration, seconds: duration }}
                              minutesStep={duration}
                              views={['hours', 'minutes']}
                              minTime={startTime.add(duration, 'minute')}
                              shouldDisableTime={(value, view) => {
                                if (!startTime.isValid()) return false
                                if (view === 'hours') return value.hour() < startTime.hour()
                                if (view === 'minutes' && value.hour() === startTime.hour())
                                  return value.minute() <= startTime.minute()

                                return false
                              }}
                              onChange={(newValue) => {
                                endField.onChange(newValue?.format('HH:mm') ?? '')
                              }}
                              slotProps={{
                                textField: {
                                  required: true,
                                  size: 'small',
                                  margin: 'normal',
                                  sx: { flex: '1 0 0' },
                                },
                              }}
                            />
                          )
                        }}
                      />

                      <Button
                        disableElevation
                        variant="contained"
                        color="error"
                        onClick={() => remove(index)}
                        sx={{ minWidth: 'unset', borderRadius: '50%', p: 1 }}
                      >
                        <DeleteIcon fontSize="small" />
                      </Button>
                    </Stack>
                  </LocalizationProvider>
                </Grid>
              ))}

              {errors?.availabilities && (
                <Grid item xs={12}>
                  <FormHelperText error>{errors?.availabilities?.message}</FormHelperText>
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* Address */}
          <Grid item xs={12}>
            <Typography variant="h6">{t('ui.form.address')}</Typography>

            <LocationPicker
              startingLocation={startingLocation}
              hasError={Boolean(errors?.address)}
              errorMessage={errors?.address?.message}
              onCoordinatesChange={handleCoordinatesChange}
              onAddressChange={handleAddressChange}
            />
          </Grid>

          {/* Images */}
          <Grid item xs={12}>
            <Typography variant="h6">Images</Typography>
            <PostImageGalleryForm
              startingImages={postImageSet}
              images={Array.from(Array(2))}
              setImages={(index, file?: File) => {
                const currentImages = getValues('postimage_set') || []

                if (file) {
                  // Create new array with file at specific index
                  const newImages = [...currentImages]
                  newImages[index] = file
                  setValue('postimage_set', newImages)
                } else {
                  // Remove image at index
                  const filteredImages = currentImages.filter((_, i) => i !== index)
                  setValue('postimage_set', filteredImages)
                }
              }}
            />

            {errors?.postimage_set && (
              <FormHelperText error required sx={{ mt: 0.5, mr: 1.75, ml: 1.75 }}>
                {errors?.postimage_set?.message}
              </FormHelperText>
            )}
          </Grid>
        </Grid>

        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '16px' }}>
          {t('ui.form.submit', 'Submit')}
        </Button>
      </Form>
    </>
  )
}

export default PostForm
