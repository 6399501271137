const t = {
  account_type: 'Account Type',
  address: 'Address',
  avatar: 'Avatar',
  certificate: 'Certificate',
  changePassword: 'Change Password',
  choose_file: 'Choose a file',
  confirm_password: 'Confirm Password',
  description: 'Description',
  duration: 'Duration',
  email: 'Email',
  fieldInvalid: 'Invalid {{value}}',
  fieldRequired: '{{value}} is required',
  first_name: 'First Name',
  last_name: 'Last Name',
  latitude: 'Latitude',
  longitude: 'Longitude',
  message: 'Message',
  name: 'Name',
  onlyNumbers: 'Only numbers are accepted',
  password_min_length: 'Password must be at least 8 characters long',
  password: 'Password',
  password2: 'Password',
  passwordsDontMatch: 'The passwords did not match',
  pickLocation: 'Pick the location of your service...',
  price: 'Price',
  registration: {
    error: {
      message: 'Registration could not be completed.',
      api_message: 'We encountered an issue during registration. Please try again later.',
    },
    success: {
      message:
        'Success! A verification email has been sent to your email address. Please check your inbox to activate your account.',
    },
  },

  reset: { password: 'Forgot password?' },
  save: 'save',
  subject: 'Subject',
  title: 'Title',
  duration_minutes: 'Duration {{duration}} (minutes)',
}

export default t
