import { Stack } from '@mui/material'

import InputImageCrop from '../UI/InputImageCrop'

const PostImageGalleryForm = ({
  startingImages,
  images,
  setImages,
  resetImages,
}: {
  startingImages?: { image: string; id: number }[]
  images: { file?: File }[]
  setImages: (index: number, file?: File) => void
  resetImages?: (imageId: number) => void
}) => {
  return (
    <Stack
      display="grid"
      gap={2}
      direction="column"
      gridTemplateColumns={'repeat(auto-fill, minmax(200px, 1fr))'}
      gridAutoRows="1fr"
    >
      {images?.map((elm, index) => {
        return (
          <InputImageCrop
            key={index}
            startingValue={startingImages?.[index]?.image}
            setImageResetCallback={() => {
              setImages(index, undefined)
              if (startingImages && startingImages.length > index) {
                resetImages?.(startingImages[index].id)
              }
            }}
            setImageCallback={(file) => setImages(index, file)}
            sx={{ position: 'relative', width: '100%', height: '100%' }}
          />
        )
      })}
    </Stack>
  )
}

export default PostImageGalleryForm
