import CalendarPage from '~/pages/management/Calendar'
import ClientsPage from '~/pages/management/Clients'
import FavoritesPage from '~/pages/management/Favorites'
import Create from '~/pages/management/post/Create'
import Edit from '~/pages/management/post/Edit'
import List from '~/pages/management/post/List'

export const managementRoutes = [
  {
    path: 'management',
    children: [
      { path: 'favorites', element: <FavoritesPage /> },
      { path: 'calendar', element: <CalendarPage /> },
      { path: 'clients', element: <ClientsPage /> },
      {
        path: 'posts',
        children: [
          { index: true, element: <List /> },
          { path: 'new', element: <Create /> },
          { path: ':id/edit', element: <Edit /> },
        ],
      },
    ],
  },
]
